import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import strings from '@constants/strings.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { User } from '@shared/models/user';
import { DataService } from '@shared/services/data-service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss'],
})
export class AuthActionComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  showPasswordPage: boolean;
  showRecoverEmailPage: boolean;
  showVerifyEmailPage: boolean;
  strings = strings.components.template.authAction;
  params: { mode: string; oobCode: string };
  showSuccess = false;
  showFailure = false;
  isLoading = false;
  email: string;
  passwordString: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private userService: UserService,
  ) {}

  get failedPasswordUpdateString(): string {
    return this.passwordString;
  }

  set failedPasswordUpdateString(value) {
    this.passwordString = value;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((queryParams: { mode: string; oobCode: string }) => {
      this.params = queryParams;
      this.chooseAndCreateForm(queryParams.mode);
    });
  }

  goToLogin() {
    this.router.navigate(internalUrls.login);
  }

  resetPassword(event: Event) {
    event.preventDefault();
    const newPassword = this.passwordForm.get('newPassword');
    const confirmPassword = this.passwordForm.get('confirmPassword');
    this.failedPasswordUpdateString = '';

    if (this.isLoading) {
      return false;
    }

    if (!this.isPassResetPasswordPrecheck(newPassword, confirmPassword)) {
      return false;
    }

    this.isLoading = true;

    this.authAction(this.params, confirmPassword.value);

    return false;
  }

  isPassResetPasswordPrecheck(newPassword, confirmPassword) {
    if (this.isResetPasswordValid(newPassword, confirmPassword)) {
      this.failedPasswordUpdateString = strings.errors.createAccount.weakPassword;
      return false;
    }
    if (!this.isSomePasswordInput(newPassword, confirmPassword)) {
      this.failedPasswordUpdateString = strings.errors.auth.passwordsDontMatch;
      return false;
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  isResetPasswordValid(newPassword, confirmPassword) {
    return newPassword.status !== 'VALID' || confirmPassword.status !== 'VALID';
  }

  // eslint-disable-next-line class-methods-use-this
  isSomePasswordInput(newPassword, confirmPassword) {
    return confirmPassword.value === newPassword.value;
  }

  authAction(params: { mode: string; oobCode: string }, password?: string) {
    const payload = { mode: params.mode, actionCode: params.oobCode, password };
    this.dataService.post(`${this.userService.firebaseServerURL}/auth-action`, payload, {}).subscribe(
      (response) => {
        if (this.showRecoverEmailPage) {
          this.email = response.email;
        }
        this.isLoading = false;
        this.showSuccess = true;
      },
      () => {
        this.isLoading = false;
        this.showFailure = true;
      },
    );
  }

  chooseAndCreateForm(mode: string) {
    const actions = {
      recoverEmail: () => this.onRecoverEmail(),
      verifyEmail: () => this.onVerifyEmail(),
      resetPassword: () => this.onResetPassword(),
    };

    if (actions.hasOwnProperty(mode)) {
      actions[mode]();
    } else {
      this.router.navigate(internalUrls.dashboard);
    }
  }

  onRecoverEmail() {
    this.emailAction();
    this.showRecoverEmailPage = true;
    this.showPasswordPage = false;
    this.showVerifyEmailPage = false;
  }

  onVerifyEmail() {
    this.emailAction();
    this.showVerifyEmailPage = true;
    this.showPasswordPage = false;
    this.showRecoverEmailPage = false;
  }

  onResetPassword() {
    this.passwordForm = this.createPasswordForm();
    this.showPasswordPage = true;
    this.showVerifyEmailPage = false;
    this.showRecoverEmailPage = false;
  }

  emailAction() {
    this.isLoading = true;
    this.authAction(this.params);
  }

  createPasswordForm(): UntypedFormGroup {
    return this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(User.MIN_PASSWORD_LENGTH)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(User.MIN_PASSWORD_LENGTH)]],
    });
  }
}
