import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { CoursePlannerComponent } from '@shared/components/templates/course-planner/course-planner.component';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';

@NgModule({
  declarations: [CoursePlannerComponent],
  imports: [CommonModule, RouterModule, AtomsModule, MoleculesModule, OrganismsModule, SharedTemplatesModule],
  exports: [CoursePlannerComponent],
})
export class TemplatesModule {}
