import { ModalModule } from '@4sellers/ngx-modialog';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { TraceService as SentryTraceService } from '@sentry/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { WidgetRegistry, SchemaFormModule } from 'ngx-schema-form';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppComponent } from '@app/app.component';
import { appRoutesModule } from '@app/app.routes';
import { AccountSettingsComponent } from '@app/components/templates/account-settings/account-settings.component';
import { AuthActionComponent } from '@app/components/templates/auth-action/auth-action.component';
import { LoginTemplateComponent } from '@app/components/templates/login-template/login-template.component';
import { TemplatesModule } from '@app/components/templates/templates.module';
import { UcWidgetRegistry } from '@shared/classes/widget-registry';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { ProcessHydrateOverlayComponent } from '@shared/components/atoms/process-hydrate-overlay/process-hydrate-overlay.component';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { DashboardTemplateComponent } from '@shared/components/templates/dashboard-template/dashboard-template.component';
import { DocumentTemplateComponent } from '@shared/components/templates/document-template/document-template.component';
import { InformationRequiredComponent } from '@shared/components/templates/information-required-template/information-required-template.component';
import { ManageMyStudyTemplateComponent } from '@shared/components/templates/manage-my-study-template/manage-my-study-template.component';
import { OutageTemplateComponent } from '@shared/components/templates/outage-template/outage-template.component';
import { PageNotFoundTemplateComponent } from '@shared/components/templates/page-not-found-template/page-not-found-template.component';
import { ProfileTemplateComponent } from '@shared/components/templates/profile-template/profile-template.component';
import { SsoTemplateComponent } from '@shared/components/templates/sso-template/sso-template.component';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';
import { WidgetsModule } from '@shared/components/widgets/widgets.module';
import { fakeAngularFireAuth } from '@shared/mocks/angular-fire-mock.service';
import { fakeBackendProvider } from '@shared/mocks/xhrbackend-mock';
import { ApplicantService, applicantServiceProvider } from '@shared/services/applicant/applicant.service';
import { ApplicationService, applicationServiceProvider } from '@shared/services/application/application.service';
import { CacheManagementService } from '@shared/services/cache-management/cache-management.service';
import {
  changeOfEnrolmentServiceProvider,
  ChangeOfEnrolmentService,
} from '@shared/services/change-of-enrolment/change-of-enrolment.service';
import { conditionServiceProvider } from '@shared/services/condition/condition.service';
import { courseServiceProvider } from '@shared/services/course/course.service';
import { DataService } from '@shared/services/data-service';
import { DefaultLandingUrlService } from '@shared/services/default-landing-url/default-landing-url.service';
import { documentServiceProvider } from '@shared/services/document/document.service';
import { enrolmentServiceProvider } from '@shared/services/enrolment/enrolment.service';
import {
  EnrolmentSummaryService,
  enrolmentSummaryServiceProvider,
} from '@shared/services/enrolment-summary/enrolment-summary.service';
import { FileUploadService } from '@shared/services/file-upload/file-upload.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { FormModelMapperService } from '@shared/services/form-model-mapper/form-model-mapper.service';
import { FullEnrolmentService } from '@shared/services/full-enrolment/full-enrolment.service';
import { AuthOnloadGuard } from '@shared/services/guards/auth-onload.guard';
import { FeatureGuard } from '@shared/services/guards/feature.guard';
import { OutageGuard } from '@shared/services/guards/outage.guard';
import { StudentIdGuard } from '@shared/services/guards/student-id.guard';
import { UnsavedDataGuard } from '@shared/services/guards/unsaved-data.guard';
import { UserAuthenticatedGuardService } from '@shared/services/guards/user-authenticated-guard.service';
import { IPLocationService } from '@shared/services/iplocation/iplocation.service';
import { jadeHydrateProvider } from '@shared/services/jade-hydrate/jade-hydrate.service';
import { LoggingService } from '@shared/services/logging/logging.service';
import { ModalCoordinatorService } from '@shared/services/modal/modal-coordinator.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { notificationServiceProvider } from '@shared/services/notification/notification.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { PlacesService, placesServiceProvider } from '@shared/services/places.service';
import { processServiceProvider } from '@shared/services/process/process.service';
import { qualificationServiceProvider } from '@shared/services/qualification/qualification.service';
import { referenceDataServiceProvider } from '@shared/services/reference-data/reference-data.service';
import { SanctionsService, sanctionsServiceProvider } from '@shared/services/sanctions/sanctions.service';
import { sentryServiceFactory, sentryErrorProvider } from '@shared/services/sentry.provider';
import { UserService } from '@shared/services/user/user.service';
import { CookieUserActivityStrategyService } from '@shared/services/user-activity/cookie-user-activity-strategy.service';
import { FirebaseUserActivityStrategyService } from '@shared/services/user-activity/firebase-user-activity-strategy.service';
import { LocalStorageUserActivityStrategyService } from '@shared/services/user-activity/localstorage-user-activity-strategy.service';
import { mockBackendUserActivityProvider } from '@shared/services/user-activity/user-activity.service';
import { WindowService } from '@shared/services/window/window.service';
import { UcMaterialModule } from '@shared/uc-material/uc-material.module';

@NgModule({
  declarations: [
    AccountSettingsComponent,
    AppComponent,
    AuthActionComponent,
    DashboardTemplateComponent,
    DocumentTemplateComponent,
    InformationRequiredComponent,
    LoginTemplateComponent,
    ManageMyStudyTemplateComponent,
    OutageTemplateComponent,
    PageNotFoundTemplateComponent,
    ProcessHydrateOverlayComponent,
    ProfileTemplateComponent,
    SsoTemplateComponent,
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule,
    AngularFirestoreModule,
    appRoutesModule,
    AtomsModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    MoleculesModule,
    NgxDatatableModule,
    NgxSmartModalModule.forRoot(),
    OrganismsModule,
    ReactiveFormsModule,
    SchemaFormModule.forRoot(),
    SharedTemplatesModule,
    TemplatesModule,
    UcMaterialModule,
    WidgetsModule,
  ],
  providers: [
    ApplicantService,
    applicantServiceProvider,
    ApplicationService,
    applicationServiceProvider,
    AuthOnloadGuard,
    CacheManagementService,
    ChangeOfEnrolmentService,
    changeOfEnrolmentServiceProvider,
    conditionServiceProvider,
    CookieUserActivityStrategyService,
    courseServiceProvider,
    DataService,
    documentServiceProvider,
    enrolmentServiceProvider,
    EnrolmentSummaryService,
    enrolmentSummaryServiceProvider,
    fakeAngularFireAuth,
    fakeBackendProvider,
    FeatureGuard,
    FileUploadService,
    FirebaseUserActivityStrategyService,
    FlashMessageService,
    FormModelMapperService,
    FullEnrolmentService,
    IPLocationService,
    jadeHydrateProvider,
    LocalStorageUserActivityStrategyService,
    LoggingService,
    mockBackendUserActivityProvider,
    ModalCoordinatorService,
    ModalService,
    {
      provide: MSAL_INSTANCE,
      useFactory: UserService.msalInstanceFactory,
    },
    MsalBroadcastService,
    MsalService,
    notificationServiceProvider,
    OutageGuard,
    PlacesService,
    placesServiceProvider,
    processServiceProvider,
    qualificationServiceProvider,
    referenceDataServiceProvider,
    SanctionsService,
    sanctionsServiceProvider,
    sentryErrorProvider,
    StudentIdGuard,
    UnsavedDataGuard,
    UserAuthenticatedGuardService,
    UserService,
    WindowService,
    DefaultLandingUrlService,
    {
      provide: WidgetRegistry,
      useClass: UcWidgetRegistry,
    },
    {
      provide: SentryTraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      // For explanation of APP_INITIALIZER, see: https://stackoverflow.com/a/44731279/8093350
      provide: APP_INITIALIZER,
      useFactory: sentryServiceFactory,
      deps: [SentryTraceService],
      multi: true,
    },
    PaymentService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
